.product__container{
    margin:1rem;
    width: 260px;
    min-height: 260px;
    border-radius: 8px;
    border: 3px solid rgb(251, 221, 175);
    overflow: hidden;
}
.product__image__container{
    width: 100%;
    height: 180px;
    overflow: hidden;
}
.product__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.product__nameContainer{
    width: 100%;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.product__name{
    padding: 0 0.5rem;
    text-align: center;
    font-size: 1.6rem;
}
.product__container:hover .product__image{
    transform: scale(1.8);
    transition: 650ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}