@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
.paginaPrincipal{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.paginaPrincipal__backgroundImage1{
    width:100%;
    height:70vh;
    background-image: url(../../images/paginaPrincipal-background-images/background-image-2.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.backgroundImage1__textContainer{
    width:230px;
    height:300px;
    position: absolute;
    top: 40px;
    right: 30px;
}
.backgroundImage1__text{
    text-align: right;
    font-size: 3.3rem;
    line-height: 1.6em;
    color: #ffffff;
    font-family: 'Dancing Script', cursive;
    text-shadow:
        2px 2px 0 #000,
        -2px -2px 0 #000,
        2px -2px 0 #000,
        -2px 2px 0 #000,
        0 2px 0 #000,
        2px 0 0 #000,
        0 -2px 0 #000,
        -2px 0 0 #000,
        2px 2px 5px #000;
}
/* ======== REDIRECTIONS ========= */
.paginaPrincipal__redirections__title{
    width:100%;
    font-size: 3rem;
    margin-top: 2rem;
    text-align: center;
}
.paginaPrincipal__redirections{
    margin-top: 2rem;
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.redirections__figure{
    width:300px;
    height:300px;
    margin: 1rem;
    overflow: hidden;
    border: 1px solid #56423d;
    border-radius: 10px;
    cursor: pointer;
}
.redirections__figure__imgContainer{
    width:100%;
    height: 65%;
}
.redirections__figure__img{
    width:100%;
    height: 100%;
    object-fit: contain;
}
.redirections__figure__figcaption{
    width:100%;
    height:35%;
    display:flex;
    align-items: center;
    justify-content: center;
}
.redirections__figure__p{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.8rem;
    padding:0 0.5rem;
    font-weight: 600;
    color: black;
    text-align: center;
}
.redirections__figure:hover .redirections__figure__img{
    transform: scale(1.05);
    transition: 350ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
@media screen and (min-width:600px){
    .backgroundImage1__textContainer{
        width:100%;
        padding: 2rem;
        position:static;   
    }
    .backgroundImage1__text{
        text-align: center;
        font-size: 4.3rem;
    }
}
@media screen and (min-width:900px){
    .backgroundImage1__text{
        font-size: 5.3rem;
    }
}