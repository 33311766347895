@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

.products{
    width:100%;
    margin-top: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.products__banner__container{
    width:100%;
    height:50vh;
}
.products__banner__image{
    width:100%;
    height:100%;
    object-fit: cover;
}
.products__title{
    text-align: center;
    margin-top: 3rem;
    padding: 0 1rem;
    font-size: 2.8rem;
    font-family: 'Dancing Script', cursive;
}
.products__displayer{
    margin-top: 4.5rem;
    width:90%;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.products__warning{
    text-align: center;
    margin-top: 4rem;
    padding:0 1rem;
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
}
@media screen and (min-width:1000px){
    .products__banner__container{
        height:60vh;
    }
}