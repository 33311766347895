/* =============== HTML PAGE FORMATTING ============ */
/* ====== MOBILE FIRST DESIGN ====== */

html{
  scroll-behavior: smooth;
}
*, *::before, *::after {
  margin:0;
  padding:0;
  outline:0;
  border: 0;
  appearance: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}
body {
  width:100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  color:black;
  overflow-x: hidden;
}
