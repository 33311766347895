/* ============ HOME SWIPER =========== */
.homeSwiper{
    margin-top: 100px;
    width: 100%;
    height: 40vh;
    background-color: black;
}
.swiper-slide{
    width:100%;
}
.homeSwiper .swiper-button-prev,
.homeSwiper .swiper-button-next{
    transform: scale(0.8);
    color:aliceblue;
}
.homeSwiper .swiper-pagination-bullet-active{
    background-color:aliceblue;
}
/* =========== HOME SWIPER IMG ========= */
.homeSwiper .homeSwiper__img{
    width:100%;
    height: 100%;
    object-fit: cover;
}
@media screen and (min-width:800px) {
    .homeSwiper{
        height: 70vh;
    }
}